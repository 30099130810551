/* General */
.ProseMirror :first-child {
  margin-top: 0;
}

/* Typography */
.ProseMirror p {
  tab-size: 8;
  white-space: pre-wrap;
}

/* Hr */
.ProseMirror hr {
  border-style: solid;
}

.ProseMirror hr.ProseMirror-selectednode {
  outline: 2px solid var(--mantine-color-blue-6);
  outline-offset: 2px;
}

/* Table */
.ProseMirror table {
  border-collapse: collapse;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.ProseMirror table colgroup {
  display: none;
}

.ProseMirror table td,
.ProseMirror table th {
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

.ProseMirror table td[data-border-width='0'],
.ProseMirror table th[data-border-width='0'] {
  border: 1px dotted var(--mantine-color-gray-3) !important;
}

.ProseMirror table td > *:last-child,
.ProseMirror table th > *:last-child {
  margin-bottom: 0 !important;
}

.ProseMirror table td > *:first-child,
.ProseMirror table th > *:first-child {
  margin-top: 0 !important;
}

.ProseMirror table th {
  background-color: var(--mantine-color-gray-1);
  font-weight: bold;
  text-align: left;
}

.ProseMirror table .selectedCell:after {
  background: var(--mantine-color-blue-6);
  opacity: 0.15;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.ProseMirror .tableWrapper {
  margin: 1.5rem 0;
  overflow-x: auto;
}

/* HTML Block */
.ProseMirror .html-block {
  display: block;
  width: 100%;
  height: 64px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--mantine-color-gray-2);
  background-color: var(--mantine-color-gray-0);
  cursor: pointer;
  position: relative;
  transition: color 200ms ease;
  margin-bottom: 0.4375rem;
}

.ProseMirror .html-block::after {
  content: 'HTML kód';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--mantine-color-gray-6);
  font-size: 1em;
}

.ProseMirror .html-block.ProseMirror-selectednode {
  border: 2px solid var(--mantine-color-blue-6);
}

/* Placeholder */
.ProseMirror [data-inject-field-name]::before {
  content: 'Neznáme pole: ' attr(data-inject-field-name);
  outline: 2px solid var(--mantine-color-red-7);
  outline-offset: 2px;
}

.ProseMirror [data-inject-section-name]::before {
  content: 'Neznáma sekcia: ' attr(data-inject-section-name);
  outline: 2px solid var(--mantine-color-red-7);
  outline-offset: 2px;
}

.ProseMirror .placeholder {
  background-color: var(--mantine-color-blue-1);
  border-radius: 4px;
  margin: 0;
  border: 2px solid var(--mantine-color-blue-1);
}

.ProseMirror div.placeholder {
  margin-bottom: 0.4375rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ProseMirror .placeholder.ProseMirror-selectednode {
  border: 2px solid var(--mantine-color-blue-6);
}

.ProseMirror .placeholder[data-context='device'] {
  background-color: var(--mantine-color-blue-1);
  border: 2px solid var(--mantine-color-blue-1);
  outline: 2px solid var(--mantine-color-red-7);
  outline-offset: 2px;
}

.ProseMirror .placeholder[data-context='device']::after {
  display: inline-block;
  content: ' (Pole mimo zariadenia)';
  margin-left: 4px;
  font-size: 0.75em;
}

.ProseMirror .placeholder.ProseMirror-selectednode[data-context='device'] {
  border: 2px solid var(--mantine-color-blue-6);
}

.ProseMirror .device-loop .placeholder[data-context='device'] {
  outline: none;
}

.ProseMirror .device-loop .placeholder[data-context='device']::after {
  display: none;
}

/* Device measurements placeholder */
.ProseMirror .device-measurements-placeholder {
  background-color: var(--mantine-color-red-1);
  border: 2px solid var(--mantine-color-red-1);
}

.ProseMirror .device-measurements-placeholder.ProseMirror-selectednode {
  border: 2px solid var(--mantine-color-red-6);
}

.ProseMirror .device-measurements-placeholder::after {
  display: block;
  content: 'Chyba: tabuľka meraní nesmie byť mimo opakovača zariadení';
  margin-top: 4px;
  font-size: 0.75em;
  color: var(--mantine-color-red-9);
  font-weight: 700;
}

.ProseMirror .device-loop .device-measurements-placeholder {
  background-color: var(--mantine-color-blue-1);
  border: 2px solid var(--mantine-color-blue-1);
}

.ProseMirror .device-loop .device-measurements-placeholder.ProseMirror-selectednode {
  border: 2px solid var(--mantine-color-blue-6);
}

.ProseMirror .device-loop .device-measurements-placeholder::after {
  display: none;
}

/* Device faults placeholder */
.ProseMirror .device-faults-placeholder {
  background-color: var(--mantine-color-red-1);
  border: 2px solid var(--mantine-color-red-1);
}

.ProseMirror .device-faults-placeholder.ProseMirror-selectednode {
  border: 2px solid var(--mantine-color-red-6);
}

.ProseMirror .device-faults-placeholder::after {
  display: block;
  content: 'Chyba: zoznam závad nesmie byť mimo opakovača zariadení';
  margin-top: 4px;
  font-size: 0.75em;
  color: var(--mantine-color-red-9);
  font-weight: 700;
}

.ProseMirror .device-loop .device-faults-placeholder {
  background-color: var(--mantine-color-blue-1);
  border: 2px solid var(--mantine-color-blue-1);
}

.ProseMirror .device-loop .device-faults-placeholder.ProseMirror-selectednode {
  border: 2px solid var(--mantine-color-blue-6);
}

.ProseMirror .device-loop .device-faults-placeholder::after {
  display: none;
}

/* Page break */
.ProseMirror .page-break {
  page-break-after: always;
  width: calc(210mm);
  height: 1px;
  border-top: 1px solid var(--mantine-color-gray-3);
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.5 !important;
}

.ProseMirror .page-break[data-force='false'] {
  border-top: 1px dashed var(--mantine-color-gray-3);
}

.ProseMirror .page-break::after {
  content: 'Zalomenie strany';
  display: block;
  color: var(--mantine-color-gray-5);
  text-align: left;
  transform: translateY(-10px) translateX(-105px);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.ProseMirror .page-break[data-force='false']::after {
  content: 'Koniec sekcie';
  transform: translateY(-10px) translateX(-87px);
}

.ProseMirror .page-break.ProseMirror-selectednode {
  outline: 2px solid var(--mantine-color-blue-6);
  outline-offset: 2px;
}

.ProseMirror .page-break.ProseMirror-selectednode::after {
  color: var(--mantine-color-blue-7);
}

/* Page sections */
.ProseMirror .page-header,
.ProseMirror .page-footer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ProseMirror .page-header {
  position: relative;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 60, 0.18); /* Same as gray 3 */
  padding-bottom: 3mm;
}

.ProseMirror .page-header::after {
  content: 'Hlavička strany';
  display: block;
  position: absolute;
  left: -96px;
  bottom: -10px;
  color: var(--mantine-color-gray-5);
  text-align: left;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.ProseMirror .page-footer {
  position: relative;
  justify-content: flex-start;
  border-top: 1px solid rgba(0, 0, 60, 0.18); /* Same as gray 3 */
  padding-top: 3mm;
}

.ProseMirror .page-footer::after {
  content: 'Päta strany';
  display: block;
  position: absolute;
  left: -75px;
  top: -10px;
  color: var(--mantine-color-gray-5);
  text-align: left;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.ProseMirror .page-header p,
.ProseMirror .page-footer p {
  margin-bottom: 0;
}

.ProseMirror .page-body {
  min-height: 290mm;
  margin-bottom: -1px;
  margin-top: -1px;
}

/* Device loop */
.ProseMirror .device-loop .device-loop-body {
  border-left: 4px solid var(--mantine-color-blue-3);
  padding-left: 8px;
}

.ProseMirror td .device-loop:last-child .device-loop-body > *:last-child,
.ProseMirror th .device-loop:last-child .device-loop-body > *:last-child {
  margin-bottom: 0 !important;
}

.ProseMirror .device-loop-body::before {
  content: 'Pre všetky zariadenia:';
  display: block;
  color: var(--mantine-color-gray-5);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 4px;
}

/* If block */
.ProseMirror .if-block {
  border-left: 4px solid var(--mantine-color-gray-2);
  padding-left: 8px;
}

.ProseMirror .if-block-start {
  width: fit-content;
  margin-bottom: 4px;
}

.ProseMirror td .if-block:last-child .if-block-body > *:last-child,
.ProseMirror th .if-block:last-child .if-block-body > *:last-child {
  margin-bottom: 0 !important;
}

.ProseMirror .if-block-start-prefix::before {
  content: 'Ak platí podmienka: ';
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--mantine-color-gray-5);
}

.ProseMirror .if-block-start-field::before {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--mantine-color-gray-7);
  background-color: var(--mantine-color-gray-1);
  padding: 2px 4px;
  border-radius: 4px;
  margin: 0 3px;
}

.ProseMirror .if-block-start-operator::before {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--mantine-color-gray-5);
}

.ProseMirror .if-block-start-operator[data-operator='not-empty']::before {
  content: 'nie je prázdne';
}

.ProseMirror .if-block-start-operator[data-operator='empty']::before {
  content: 'je prázdne';
}

.ProseMirror .if-block-start-operator[data-operator='equals']::before {
  content: 'rovná sa';
}

.ProseMirror .if-block-start-operator[data-operator='not-equals']::before {
  content: 'nerovná sa';
}

.ProseMirror .if-block-start-value::before {
  content: attr(data-value);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--mantine-color-gray-7);
  margin: 0 3px;
}
