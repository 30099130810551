/* prettier-ignore */
/* @tailwind base; */
/*
=====================================================================
We do not use Tailwind base layer because the same functionality is
implemented by the Mantine library
=====================================================================
*/

/* prettier-ignore */
@tailwind components;

/* prettier-ignore */
@tailwind utilities;

body {
  overflow-x: hidden;
}

.moveable-line {
  /* hide blue lines around the movable object */
  background-color: transparent !important;
}
