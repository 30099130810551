.ag-theme-alpine {
  --ag-borders: none;
  --ag-font-size: 16px;
  font-family: 'Roboto', sans-serif;
  --ag-border-radius: 4px;
  --ag-checkbox-border-radius: 4px;

  /* --ag-header-height: 72px; */
  --ag-checkbox-checked-color: white;
  --ag-row-hover-color: #f6f6f8;
  --ag-header-background-color: #f6f6f8;

  --ag-cell-horizontal-padding: 12px;
}

.ag-theme-alpine.measurements-table {
  --ag-cell-horizontal-padding: 8px;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.ag-floating-filter-input .ag-input-wrapper {
  border: 1px solid #c5c6d3;
  border-radius: 4px;
}

.ag-checkbox .ag-input-wrapper {
  border: 1px solid #c5c6d3;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.ag-checkbox-input-wrapper::after {
  content: '';
}

.ag-checked {
  background: #3396f3;
}

.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
.ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: none;
}

.ag-pinned-right-header {
  border-left: none;
}

.ag-pinned-right-cols-container {
  box-shadow: -10px 0px 10px rgba(40, 41, 52, 0.04);
}

.ag-row,
.ag-row-odd {
  background-color: var(--ag-background-color);
  border-bottom: 1px solid #ebebf0;
}

.ag-row {
  z-index: 5;
}

.ag-row:hover {
  z-index: 6;
}

.ag-cell {
  display: flex;
  align-items: center;
}

.ag-floating-filter-button {
  display: none;
}

.ag-header-cell-menu-button {
  display: none;
}

.ag-center-cols-container {
  min-width: 100% !important;
}

.measurements-table .ag-header-cell-text {
  font-size: 14px;
  letter-spacing: -0.04px;
}

@media (min-width: 1920px) {
  .measurements-table .ag-header-cell-text {
    font-size: 15px;
  }
}
